import React, { useRef, useState, useEffect } from "react"
import styled from "@emotion/styled"

import useWindowSize from "../../../hooks/useWindowSize"
import { isMobileWindow } from "../../../utils/render-util"

const TooltipWrapper = styled.div`
  position: relative;
  display: inline;
  text-decoration: underline;
  cursor: pointer;
`

const Tooltip = styled.span`
  visibility: ${(props) => (props.toggleTooltip ? "visible" : "hidden")};
  width: 300px;
  background-color: #222;
  border: 1px solid transparent;
  color: ${(props) => props.theme.colors.grayscale.white};
  text-align: left;
  border-radius: 3px;
  padding: 8px 21px;
  font-size: 13px;
  opacity: 0.9;

  position: absolute;
  z-index: 1;
  right: ${(props) =>
    props?.tooltipOptions?.right ? props.tooltipOptions.right : "auto"};
  left: ${(props) =>
    props?.tooltipOptions?.left ? props.tooltipOptions.left : "50%"};
  bottom: ${(props) =>
    props?.tooltipOptions?.bottom ? props.tooltipOptions.bottom : "100%"};
  top: ${(props) =>
    props?.tooltipOptions?.top ? props.tooltipOptions.top : "auto"};
  transform: ${(props) =>
    props?.tooltipOptions?.transform
      ? props.tooltipOptions.transform
      : "translateX(-50%)"};

  &::before,
  &::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
  }

  &::before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    ${(props) =>
      props?.tooltipOptions?.top === "100%" ? "top" : "bottom"}: -8px;
    left: ${(props) =>
      props.tooltipOptions.tipPosition === "left"
        ? "75px"
        : !props.tooltipOptions.tipPosition
        ? "50%"
        : "auto"};
    right: ${(props) =>
      props.tooltipOptions.tipPosition === "right" ? "75px" : "auto"};
    margin-left: -10px;
    border-${(props) =>
      props?.tooltipOptions?.top === "100%"
        ? "bottom"
        : "top"}: 8px solid transparent;
  }

  &::after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    ${(props) =>
      props?.tooltipOptions?.top === "100%" ? "top" : "bottom"}: -6px;
    left: ${(props) =>
      props.tooltipOptions.tipPosition === "left"
        ? "75px"
        : !props.tooltipOptions.tipPosition
        ? "50%"
        : "auto"};
    right: ${(props) =>
      props.tooltipOptions.tipPosition === "right" ? "75px" : "auto"};
    margin-left: -8px;
    border-${(props) =>
      props?.tooltipOptions?.top === "100%" ? "bottom" : "top"}-color: #222;
    border-${(props) =>
      props?.tooltipOptions?.top === "100%" ? "bottom" : "top"}-style: solid;
    border-${(props) =>
      props?.tooltipOptions?.top === "100%" ? "bottom" : "top"}-width: 6px;
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    ${TooltipWrapper}:hover & {
      visibility: visible;
    }
  }
`

const RichTextTooltip = ({ node, children }) => {
  const [tooltipOptions, setTooltipOptions] = useState({
    left: "",
    right: "",
    transform: "",
    tipPosition: "",
    top: "",
    bottom: "",
  })

  const [toggleTooltip, setToggleTooltip] = useState(false)

  const windowSize = useWindowSize()

  const wrapperRef = useRef()
  const tooltipRef = useRef()

  const handleTooltipOptions = () => {
    const screenPadding = 16

    const wrapperRect = wrapperRef.current.getBoundingClientRect()
    const tooltipRect = tooltipRef.current.getBoundingClientRect()

    const wrapperRightX = wrapperRect.x + wrapperRect.width
    const tooltipRightX = tooltipRect.x + tooltipRect.width

    let options = {}

    if (tooltipRect.x < 0) {
      options = {
        left: "0",
        right: "auto",
        transform: `translateX(${-wrapperRect.x + screenPadding}px)`,
        tipPosition: "left",
      }
    } else if (tooltipRightX > window.outerWidth) {
      options = {
        left: "auto",
        right: "0",
        transform: `translateX(${
          window.outerWidth - wrapperRightX - screenPadding
        }px)`,
        tipPosition: "right",
      }
    }

    if (isMobileWindow(windowSize.width)) {
      if (tooltipRect.y < 0) {
        options = {
          ...options,
          top: "100%",
          bottom: "auto",
        }
      } else if (tooltipRect.y > window.outerHeight - wrapperRect.y - 120) {
        options = {
          ...options,
          top: "auto",
          bottom: "100%",
        }
      }
    }

    setTooltipOptions({
      ...tooltipOptions,
      ...options,
    })
  }

  useEffect(() => {
    if (wrapperRef.current && tooltipRef.current) {
      const handleClickOutside = (e) => {
        if (
          tooltipRef.current &&
          !tooltipRef.current.contains(e.target) &&
          wrapperRef.current &&
          !wrapperRef.current.contains(e.target)
        ) {
          setToggleTooltip(false)
        }
      }

      window.addEventListener("resize", handleTooltipOptions)
      document.addEventListener("mousedown", handleClickOutside)

      handleTooltipOptions()

      return () => {
        window.removeEventListener("resize", handleTooltipOptions)
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }
  }, [wrapperRef, tooltipRef])

  return (
    <TooltipWrapper
      ref={wrapperRef}
      onClick={() => {
        if (isMobileWindow(windowSize.width)) {
          handleTooltipOptions()

          setToggleTooltip(!toggleTooltip)
        }
      }}
    >
      {children}
      <Tooltip
        ref={tooltipRef}
        dangerouslySetInnerHTML={{
          __html: node?.data?.target?.longText?.longText.replace(
            /\n/g,
            "<br />"
          ),
        }}
        tooltipOptions={tooltipOptions}
        toggleTooltip={toggleTooltip}
      />
    </TooltipWrapper>
  )
}

export default RichTextTooltip
